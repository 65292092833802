import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LoginResponse } from '@app/auth/models/login-response.model';
import { AuthenticationService } from '@app/core/services/authentication.service';
import { SsoService } from '@app/core/services/sso.service';
import { BehaviorSubject, EMPTY, Observable, of, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

	private refreshTokenInProgress: boolean;
	private refreshTokenSubject = new BehaviorSubject<string>(null);
	constructor(private authenticationService: AuthenticationService,
		private ssoService: SsoService) {
	}

	intercept(request: HttpRequest<Object>, next: HttpHandler): Observable<HttpEvent<Object>> {

		if (this.authenticationService.isAccessTokenExpired()) {
			this.authenticationService.removeAccessToken();
		}

		if (this.authenticationService.isRefreshTokenExpired()) {
			this.authenticationService.removeRefreshToken();
		}

		if (['/sso/organizations', '/sso/oauth/token', '/sso/oauth/auth-with', '/sso/oauth/token/exchange', '/sso/oauth/token/exchange/external'].includes(request.url)) {
			return next.handle(request);
		}

		request = this.addAuthenticationToken(request);

		return next.handle(request).pipe(
			catchError((response: HttpErrorResponse) => {
				if (response.status === 401) {
					if (this.refreshTokenInProgress) {
						return this.refreshTokenSubject.pipe(
							filter(accessToken => accessToken !== null),
							take(1),
							switchMap(() => next.handle(this.addAuthenticationToken(request)))
						);
					} else {
						if (!!this.authenticationService.getRefreshToken()) {
							this.refreshTokenInProgress = true;
							this.refreshTokenSubject.next(null);
						}

						return of(null).pipe(
							filter(() => !this.authenticationService.getLogoutInProgress()),
							switchMap(() => {
								if (!!this.authenticationService.getRefreshToken()) {
									return this.ssoService.refreshToken$().pipe(
										switchMap((loginResponse: LoginResponse) => {
											this.authenticationService.setAccessToken(loginResponse.access_token);
											this.authenticationService.setRefreshToken(loginResponse.refresh_token);

											this.refreshTokenInProgress = false;
											this.refreshTokenSubject.next(loginResponse.access_token);

											return next.handle(this.addAuthenticationToken(request));
										}),
										catchError((_: HttpErrorResponse) => {
											this.refreshTokenInProgress = false;
											this.setRedirectUrl();
											this.authenticationService.logout();

											return EMPTY;
										})
									);
								}

								this.authenticationService.setLogoutInProgress(true);

								this.refreshTokenInProgress = false;
								this.setRedirectUrl();
								this.authenticationService.logout();

								return EMPTY;
							})
						);
					}
				}

				// the system will automatically log the user out if they have been terminated while logged in
				if (response.status === 403 && response.error?.errors['organization.locked'] && request.url === (`${environment.apiUrl}/me`)) {
					this.authenticationService.setLogoutInProgress(true);
					this.authenticationService.logout();

					return EMPTY;
				}

				return throwError(response);
			})
		);
	}

	private addAuthenticationToken(request: HttpRequest<Object>): HttpRequest<Object> {
		const accessToken = this.authenticationService.getAccessToken();

		if (!accessToken) {
			return request;
		}

		return request.clone({
			setHeaders: {
				Authorization: `Bearer ${accessToken}`
			}
		});
	}

	private setRedirectUrl(): void {
		const redirectUrl = window.location.pathname + window.location.search;
		localStorage.setItem('redirectUrl', redirectUrl);
	}
}
